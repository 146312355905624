import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

export const LinkMap = {
  Visualization: "bc86a45f8ec6",
  Charts: "bc86a45f8ec6",
  "Line Charts": "bc86a45f8ec6",
  "Bar Charts": "bc86a45f8ec6",
  "Pie Charts": "bc86a45f8ec6",
  "Donut Charts": "bc86a45f8ec6",
  "Box Plots": "bc86a45f8ec6",
  "Bubble Charts": "bc86a45f8ec6",
  "Word Cloud": "bc86a45f8ec6",
  Sunburst: "bc86a45f8ec6",
  TreeMap: "bc86a45f8ec6",
  FlameGraph: "bc86a45f8ec6",
  CalendarView: "bc86a45f8ec6",
  Sankey: "bc86a45f8ec6",
  "Concurrence Matrix": "bc86a45f8ec6",
  "Connected Node Graphs": "bc86a45f8ec6",
  "Chord Diagram": "bc86a45f8ec6",

  "Layouts — LineChart": "4ea650bba7a4",
  "LineChart-Data": "4ea650bba7a4",
  "LineChart-Simple": "4ea650bba7a4",
  "LineChart-Interaction": "4ea650bba7a4",
  "LineChart-Zoom": "4ea650bba7a4",
  "LineChart-Brush": "4ea650bba7a4",

  "Layouts — BarChart": "7f240ea760ec",
  "BarChart- Cartesien Coordinate": "7f240ea760ec",
  "BarChart- Vertical": "7f240ea760ec",
  "BarChart- Stacked": "7f240ea760ec",
  "BarChart- Grouped": "7f240ea760ec",
  "BarChart- Horizontal": "7f240ea760ec",
  "BarChart- Positive/Negative": "7f240ea760ec",
  "BarChart- Biaxial": "7f240ea760ec",

  "Layouts — PieChart": "3794dc677e33",
  "Layouts — DonutChart": "3794dc677e33",
  "Layouts — Polar Coordinate": "3794dc677e33",

  "Layouts — TreeMap": "4e0c47e3c40",
  "TreeMap — Ben Shneiderman": "4e0c47e3c40",
  "TreeMap — Human Computer Interaction Lab": "4e0c47e3c40",
  "TreeMap — Brian Johnson": "4e0c47e3c40",
  "TreeMap-Circle Packing": "4e0c47e3c40",

  "Layouts-ICicle": "bc5f0f95d710",
  "Layouts-Flame Graph": "bc5f0f95d710",
  "Layouts-Sunburt": "bc5f0f95d710",

  "Platforms-Chartio": "410f4bb46576",
  "Platforms-DataMatic": "410f4bb46576",
  "Platforms-DataWrapper": "410f4bb46576",
  "Platforms-GoodData": "410f4bb46576",
  "Platforms-Google DataStudio": "410f4bb46576",
  "Platforms-Flourish": "410f4bb46576",
  "Platforms-Infogram": "410f4bb46576",
  "Platforms-Kepler.gl": "410f4bb46576",
  "Platforms-Plotly": "410f4bb46576",
  "Platforms-Power BI": "410f4bb46576",
  "Platforms-Qlik": "410f4bb46576",
  "Platforms-Tableu": "410f4bb46576",

  "Lib-Rechart": "75aa40702ab3",
  "Lib-Visx": "75aa40702ab3",
  "Lib-Victory": "75aa40702ab3",
  "Lib-Nivo": "75aa40702ab3",
  "Lib-React-vis": "75aa40702ab3",

  "Test/Prod": "9642e5c2ff6b",

  "TraceMap-Samples": "c6cd3d82e288",
  "TraceChart-Samples": "f7351bac7221",
  "Heatmap-Samples": "69ebf3f3c187",
}

const storiesGroup = [
  {
    title: "Visualization",
    storyPart: [
      {
        title: "Visualization Layouts",
        postId: "bc86a45f8ec6",
      },
      {
        title: "Visualization Layouts-LineChart",
        postId: "4ea650bba7a4",
      },
      {
        title: "Visualization Layouts-BarChart",
        postId: "7f240ea760ec",
      },
      {
        title: "Visualization Layouts-Pie and Donut Charts",
        postId: "3794dc677e33",
      },
      {
        title: "Visualization Layouts-TreeMap",
        postId: "4e0c47e3c40",
      },
      {
        title: "Visualization Layouts-ICicle, Flame Graph ve Sunburst",
        postId: "bc5f0f95d710",
      },
    ],
  },

  {
    title: "Visualization Samples",
    storyPart: [
      {
        title: "LineChart Samples",
        postId: "abd4b0c8cd6c",
      },
      {
        title: "BarChart Samples",
        postId: "b508a82cc056",
      },

      {
        title: "Cytoscape Samples",
        postId: "c6cd3d82e288",
      },

      {
        title: "ReactFlow Samples",
        postId: "cd454557ebb1",
      },
      {
        title: "VisJS Samples",
        postId: "cd454557ebb1",
      },

      {
        title: "TraceChart Samples",
        postId: "f7351bac7221",
      },

      {
        title: "FlameGraph Samples",
        postId: "eb70f41a2803",
      },

      {
        title: "Heatmap Samples",
        postId: "69ebf3f3c187",
      },
    ],
  },
  {
    title: "Libs And Tools",
    storyPart: [
      {
        title: "React Chart Kütüphaneleri",
        postId: "75aa40702ab3",
      },
      {
        title: "Visualization Platforms",
        postId: "410f4bb46576",
      },
    ],
  },
  {
    title: "Problems",
    storyPart: [
      {
        title: "Görselleştirmede Test/Prod Ortam Farklılıklar",
        postId: "9642e5c2ff6b",
      },
    ],
  },
  {
    title: "Concepts",
    storyPart: [
      {
        title: "İstatistik İle İlgili Bazı Temel Kavramlar",
        postId: "5a51f5228286",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Visualization",
  path: "visualization-and-charts",
}

const VisualizationAndChartsPage = () => (
  <StoryGroup
    title={context.title}
    complex={true}
    stories={storiesGroup}
    linkMap={context.linkMap}
  />
)
export default VisualizationAndChartsPage
